<template>
  <el-dialog
    id="FindParentRulesDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="1077px"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container v-loading="loading">
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              placeholder="Пошук"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="filter"
              @keydown.esc.native="resetFilter($event, this)"
            />
          </div> </el-row
      ></el-header>
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="findRulesTable"
          id="findRulesTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="multiple"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="rules"
          :gridOptions="gridOptions"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
          :sideBar="sideBar"
          :pivotMode="false"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { bus } from "@/main";
  import { _ } from "vue-underscore";
  import { AgGridVue } from "@ag-grid-community/vue";
  import { AllModules } from "@ag-grid-enterprise/all-modules";
  import Icon from "@/components/IconPack";

  function parseError(error) {
    if (typeof error == "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join("; ");
    } else return error;
  }

  export default {
    name: "FindParentRules",
    components: { AgGridVue, Icon },
    data() {
      var that = this;
      return {
        loading: false,
        modules: AllModules,
        dialogShow: false,
        dialogHeight: 0,
        resizeObserver: null,
        title: $tt("Пошук"),
        target: null,
        model: {},
        initModel: {},
        filterString: "",
        selectedRows: null,
        selectedParentRules: [],
        waitForAnswer: false,
        keyUpTimer: null,
        filterHasChanged: false,
        gridApi: null,
        gridColumnApi: null,
        noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
        gridOptions: {
          suppressCellSelection: true,
          getRowNodeId: function(data) {
            return data._id;
          },
          immutableData: true,
          rowHeight: 32,
          floatingFiltersHeight: 32,
          statusBar: {
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
            ],
          },
        },
        sideBar: {
          toolPanels: ["columns", "filters"],
          position: "left",
          hiddenByDefault: false,
        },
        defaultColDef: {
          suppressNavigable: true,
          cellClass: "no-border",
          resizable: true,
          sortable: true,
          tooltipValueGetter: function(params) {
            return params.value;
          },
          floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
          suppressMenu: false,
          filter: "agTextColumnFilter",
          floatingFilterComponentParams: { suppressFilterButton: true },
        },

        columns: [
          {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            width: "30px",
          },
          {
            headerName: $tt("Назва"),
            field: "name",
            flex: 2,
          },
          {
            headerName: $tt("План, одиниці вимірювання"),
            field: "planMeasure",
            flex: 1,
            type: "rightAligned",
            valueGetter: function(params) {
              var labelItem = that.selectOptions.planMeasure.find((option) => option.value == params.data.planMeasure);
              if (labelItem) return $tt(labelItem.label);
              else return $tt("Помилковий параметр: ") + params.data.planMeasure;
            },
          },
          {
            headerName: $tt("План, значення"),
            field: "plan",
            flex: 1,
            type: "rightAligned",
          },
          {
            headerName: $tt("Бонус, одиниці вимірювання"),
            field: "bonusMeasure",
            flex: 1,
            valueGetter: function(params) {
              var labelItem = that.selectOptions.bonusMeasure.find((option) => option.value == params.data.bonusMeasure);
              if (labelItem) return $tt(labelItem.label);
              else return $tt("Помилковий параметр: ") + params.data.bonusMeasure;
            },
          },
          {
            headerName: $tt("Бонус, значення"),
            field: "bonus",
            flex: 1,
            type: "rightAligned",
          },
          {
            headerName: $tt("Примітка"),
            field: "notes",
            flex: 2,
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      };
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    },
    created() {
      var that = this;
      bus.$on("forms.rules.find.show", (data) => {
        if (!that.dialogShow) {
          that.title = (data && data.title) || that.title;
          that.filterString = "";
          that.initModel = _.extend({}, data ? data : {});
          that.loading = true;
          that.dialogShow = true;
        }
      });
      bus.$on("forms.rules.find.hide", () => {
        that.dialogShow = false;
        that.resizeObserver = null;
      });
    },
    computed: {
      rules() {
        return this.$store.getters["restricts/restrictsAndRulesFiltered"](this.filterString);
      },
      selectOptions() {
        return this.$store.getters["main/selectionOptions"].restricts;
      },
    },
    watch: {
      dialogHeight(value) {
        var containerHeight = value - 48 - 72 - 48;
        // 48 - header 72 - footer 48 - find input 20 - padding 16 - 2 header
        this.$refs.flex_container.style.height = `${containerHeight}px`;
      },
    },
    methods: {
      onGridReady() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        var state = this.$store.getters["main/userSettings"]("findParentRulesGridColumnState");
        if (state) this.gridColumnApi.setColumnState(state);
      },
      observeHeight() {
        var that = this;
        if (!this.resizeObserver) {
          this.resizeObserver = new ResizeObserver(function() {
            that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
          });
          this.resizeObserver.observe(this.$el.children[0]);
        }
      },
      unobserveHeight() {
        this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
        this.resizeObserver = null;
        this.dialogHeight = 0;
      },
      resetFilter(event) {
        if (this.filterString != "") {
          event && event.stopPropagation();
          this.$refs.filter.clear();
        }
      },
      initSelection() {
        this.gridOptions.api.deselectAll();
        if (this.selectedParentRules) {
          this.selectedParentRules.forEach((ruleId) => {
            var node = this.gridOptions.api.getRowNode(ruleId);
            if (node) node.setSelected(true);
          });
        }
      },
      onOpen() {
        this.observeHeight();
      },
      onClose() {
        this.unobserveHeight();
      },
      onOpened() {
        var that = this;
        if (!this.$store.getters["restricts/restrictsAndRules"]) {
          this.$store
            .dispatch("restricts/loadRestrictsAndRules", { id: this.initModel.contractId })
            .then(() => {
              that.selectedParentRules = that.initModel && that.initModel.parentRules ? that.initModel.parentRules : null;
              that.initSelection();
            })
            .finally(() => {
              that.loading = false;
            });
        } else {
          that.initSelection();
          that.loading = false;
        }
      },
      onCommit() {
        if (this.selectedParentRules && this.selectedParentRules.length) {
          this.$emit("find-commit", { target: this.target, result: this.selectedParentRules });
        } else this.$emit("find-commit", { target: this.target, result: [] });
        this.dialogShow = false;
      },
      onFilterStringChange(value) {},
      onGridSelectionChanged() {
        var rows = this.gridOptions.api.getSelectedRows();
        if (rows && rows.length > 0) {
          this.selectedParentRules = rows.map((row) => row._id);
        } else {
          this.selectedParentRules = null;
        }
      },
      onFormEnter() {},
      onGridColumnsChange() {
        var that = this;
        if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
        this.changeColumnsTimer = setTimeout(() => {
          var state = that.gridColumnApi.getColumnState();
          clearTimeout(that.changeColumnsTimer);
          that.$store.dispatch("main/updateUserSettings", { findParentRulesGridColumnState: state });
        }, 500);
      },
      gridLocale(key, defaultValue) {
        var localized = $tt("grid." + key);
        return localized ? localized : defaultValue;
      },
    },
  };
</script>

<style lang="scss" scope>
  #FindParentRulesDialog .header2 {
    background: #fff;
    line-height: 32px;
    border-bottom: 1px solid #ccc !important;
    padding: 0 6px !important;
    align-items: center;
    margin-left: -16px;
    margin-right: -16px;
  }

  #FindParentRulesDialog.my-dialog .el-dialog--center .el-dialog__body {
    padding: 0px 16px 0 16px !important;
  }
</style>
